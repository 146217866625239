/* Instanciamos plugins */

//Smooth scroll para links menu
//var easeInCubic = new SmoothScroll('[data-easing="easeInCubic"]', {easing: 'easeInCubic'});

//Animations on scroll
//AOS.init();


$(function() {

    var form = $('#contactForm');
    var formMessages = $('#msg-response');

    $(form).submit(function(event) {
        // Cuando hacemos submit, detenemos al evento por defecto. 
        event.preventDefault();

        // Guardamos datos introducidos en una var
        var formData = $(form).serialize();

        var name = $("#name").val();
        var email = $("#email").val();
        var message = $("#message").val();
    
        //Enviamos peticion
        $.ajax({
            type: 'POST',
            url: 'php/sendEmail.php',
            //data: "name=" + name + "&email=" + email + "&message=" + message,
            data: formData
        }).done(function(response) {
            //Añadimos clase success al div de respuesta
            $(formMessages).find('p').removeClass('error');
            $(formMessages).find('p').addClass('success');
        
            // Set the message text.
            $(formMessages).find('p').text('Mensaje enviado correctamente');
            //console.log(response);
        
            // Clear the form.
            $('#name').val('');
            $('#email').val('');
            $('#message').val('');

           // setTimeout(function(){ $(formMessages).fadeOut(); }, 4000);

        }).fail(function(data) {
            // Make sure that the formMessages div has the 'error' class.
            $(formMessages).find('p').removeClass('success');
            $(formMessages).find('p').addClass('error');
        
            // Set the message text.
            if (data.responseText !== '') {
                $(formMessages).text(data.responseText);
            } else {
                $(formMessages).find('p').text('Oops! No hemos podido enviar tu mensaje. Reintentalo más tarde.');
            }
        });

    });
});

// Validations 
$(function() {
    'use strict';
    window.addEventListener('load', function() {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add('was-validated');
        }, false);
      });
    }, false);
});